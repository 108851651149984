@use 'mixins' as *;

body {
  .uppercased {
    text-transform: uppercase;
  }

  .capitalized {
    text-transform: capitalize;
  }

  .bold {
    font-weight: 600;
  }

  .fw {
    &-500 {
      font-weight: 500;
    }

    &-600 {
      font-weight: 600;
    }
  }

  .text-center {
    text-align: center;
  }

  .text-end {
    text-align: end;
  }

  .three-dots {
    @include three-dots();
  }

  .label {
    @include text(0.875, 1.25, 500);
    @include space((margin:(top:1, bottom: .5)));
  }

  .no-wrap {
    white-space: nowrap;
  }

  .break-spaces {
    white-space: break-spaces;
  }

  .break-all {
    word-break: break-all;
  }


  .link {
    @include as-link(none);

    &-underlined {
      @include as-link();
    }

    &-simple {
      @include set-color(selected);
      text-decoration: underline;
      font-weight: bold;
    }

    &-text {
      @include as-link(none, default);
    }

    &-text-primary {
      &-underlined {
        @include as-link(underline, default, textPrimary);
      }
    }
  }

  .error-message {
    @include set-color(required);
    word-break: break-word;

    &.bold {
      * {
        font-weight: 600;
      }
    }

    * {
      @include set-color(required);
    }
  }

  .text-shadow {
    &-dark {
      text-shadow: 0px 1px 2px black, 0 0 1em black, 0 0 0.2em blue;
    }

    &-light {
      text-shadow: 0px 1px 2px white, 0 0 1em white, 0 0 0.2em blue;
    }
  }
}