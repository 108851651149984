@use 'nested' as *;
@use 'functions' as *;

$mobile: width(screen, mobile);
$ipod: width(screen, ipod);
$tablet: width(screen, tablet);
$desktop: width(screen, desktop);

$beforeMobile: calc($mobile - 0.1px);
$beforeIpod: calc($ipod - 0.1px);
$beforeTablet: calc($tablet - 0.1px);
$beforeDesktop: calc($desktop - 0.1px);


//#region min
@mixin min-mobile {
  @media screen and (min-width: $mobile) {
    @content;
  }
}

@mixin min-ipod {
  @media screen and (min-width: $ipod) {
    @content;
  }
}

@mixin min-tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin min-desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

//#endregion

//#region before
@mixin max-mobile {
  @media screen and (max-width: $beforeMobile) {
    @content;
  }
}

@mixin max-ipod {
  @media screen and (max-width: $beforeIpod) {
    @content;
  }
}

@mixin max-tablet {
  @media screen and (max-width: $beforeTablet) {
    @content;
  }
}

@mixin max-desktop {
  @media screen and (max-width: $beforeDesktop) {
    @content;
  }
}

//#endregion

//#region min before
@mixin min-mobile-max-ipod {
  @media screen and (min-width: $mobile) and (max-width: $beforeIpod) {
    @content;
  }
}

@mixin min-mobile-max-tablet {
  @media screen and (min-width: $mobile) and (max-width: $beforeTablet) {
    @content;
  }
}

@mixin min-ipod-max-tablet {
  @media screen and (min-width: $ipod) and (max-width: $beforeTablet) {
    @content;
  }
}

@mixin min-tablet-max-desktop {
  @media screen and (min-width: $tablet) and (max-width: $beforeDesktop) {
    @content;
  }
}

//#endregion