@use 'sass:list';
@use 'nested' as *;
@use 'variables' as *;
@use 'functions' as *;

// desktop or mobile
@mixin set-main-padding($screen) {
  @include space((padding: (horizontal: padding(mainContainer, $screen))));
}

@mixin set-content-padding($screen) {
  @include space((padding: (horizontal: padding(contentContainer, $screen))));
}

// set-space(margin);
// set-space(margin, 1);
// space(((margin: top), padding-left, padding: ((bottom: 0.75), top) gap));

@function create-css-property($cssProperty, $prefix) {
  @if($cssProperty =='') {
    $cssProperty: #{$prefix};
  }

  @else {
    $cssProperty: #{$cssProperty}-#{$prefix};
  }

  @return $cssProperty;
}

@function prefixes-to-css-properties($prefixes) {
  $result: (
  );

$cssProperty: '';

@each $prefix in $prefixes {
  @if($prefix !='') {

    @if($prefix =='x' or $prefix=='horizontal') {
      $result: append($result, create-css-property($cssProperty, left));
      $result: append($result, create-css-property($cssProperty, right));
      $cssProperty: '';
    }

    @else if($prefix =='y' or $prefix=='vertical') {
      $result: append($result, create-css-property($cssProperty, top));
      $result: append($result, create-css-property($cssProperty, bottom));
      $cssProperty: '';
    }

    @else {
      $cssProperty: create-css-property($cssProperty, $prefix);
    }
  }
}

@if($cssProperty !='') {
  $result: append($result, $cssProperty);
}

@return $result;
}

$allowedSpaceValues: (
  auto,
  unset,
  inherit
);

@mixin space($dataset, $value: 1, $prefixes: ()) {
  $datasetType: type-of($dataset);
  //@debug $datasetType;

  @if($datasetType =='number' or index($allowedSpaceValues, $dataset) !=null) {
    $value: $dataset;
    $dataset: '';
    $datasetType: type-of($dataset);
  }

  @if($datasetType =='string') {
    $prefixes: list.append($prefixes, $dataset);
    $cssProperties: prefixes-to-css-properties($prefixes);

    @each $prop in $cssProperties {

      @if(type-of($value)=='number' and unit($value)=='') {
        #{$prop}: calc($value * space(default));
      }

      @else {
        #{$prop}: $value;
      }

      //@debug #{$prop}+': '+$value;
    }
  }

  @else if($datasetType =='list') {
    @each $datasetItem in $dataset {
      @include space($datasetItem, $value, $prefixes);
    }
  }

  @else if($datasetType =='map') {
    @each $datasetKey in map-keys($dataset) {
      $mapPrefixes: list.append($prefixes, $datasetKey);
      $data: map-get($dataset, $datasetKey);
      @include space($data, $value, $mapPrefixes);
    }
  }
}