@use 'mixins' as *;

html {
  --mdc-plain-tooltip-container-shape: 4px;
  //--mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: var(--text-inactive);
  --mdc-plain-tooltip-supporting-text-color: var(--default);
  //--mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  //--mdc-plain-tooltip-supporting-text-size: 12px;
  //--mdc-plain-tooltip-supporting-text-weight: 400;
  //--mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;

  .mat-mdc-tooltip,
  .mdc-tooltip__surface {
    @include space(margin, 0);
  }

  mat-tooltip-component {
    @include relative((top: -.5rem));
  }
}