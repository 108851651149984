@use 'mixins' as *;

body {
  *[id^="anchor-"] {
    @include relative((top: calc(-1 * (height(header, default) + margin(container)))));
  }

  .section {
    &-header {
      @include flex(flex-start);
      @include space(gap, .5);
      flex-wrap: wrap;

      &-text {
        @include text(1.5, 2, 600);
        //white-space: nowrap;
      }

      &-link {
        @include as-link();
      }

      &-buttons-container {
        @include flex();
        @include space(gap);
        margin-left: auto
      }
    }

    &-subheader {
      @include flex(flex-start);
      @include space((margin: (vertical: .75), gap: .5));

      &-text {
        @include text(1, 1.5);
      }

      &-link {
        @include as-link();
      }

      &-bold {
        @include text(1, 1.125, 500);
      }

      &.wrap {
        flex-wrap: wrap;
      }
    }
  }

  .block {
    &-header {
      @include flex(space-between);
      flex-wrap: wrap;

      &-text {
        @include flex();
        @include text(1.125, 1.5, 600);
        word-break: break-word;
        flex-wrap: wrap;
      }

      &-navigation {
        @include flex(flex-end);

        .mat-icon {
          path:nth-of-type(1) {
            stroke: none;
          }
        }

        button:disabled {
          .mat-icon {
            @include set-path($stroke: border, $fill: default);
          }
        }
      }
    }

    &-subheader {
      @include flex(space-between);

      &-text {
        @include text(.875, 1.25);
        //@include set-color(textInactive);
      }

      &-navigation {
        @include flex(flex-end);
      }
    }

    &-description {
      @include text(0.75, 1, 500);
    }
  }

  .confirmation-section {
    width: 100%;
  }
}

@include max-tablet {
  body {
    .section {
      &-subheader {

        &-text,
        &-link {
          @include text(.875, 1.125);
        }
      }
    }
  }
}