@use 'mixins' as *;

html {
  --mat-sidenav-container-width: auto;
}

.mat-drawer-container {
  height: 100%;

  .mat-drawer {
    @include set-z-index(sidebar);
  }

  // hide sidebar
  .mat-drawer-content {
    position: static;
  }

  &.only-backdrop {
    .mat-drawer {
      display: none;
    }
  }

  .mat-drawer-content {
    @include set-background-color(background);
    //@include hide-scrollbar();
  }

  .mat-drawer-inner-container {
    @include hide-scrollbar();
    -webkit-transition: width .5s;
    transition: width .5s;
  }

  &.sidebar-panel {
    &-big {
      .mat-drawer-inner-container {
        @include set-width(panel, sidebar, big);

        .sidebar-layout {
          @include set-width(panel, sidebar, big);
        }
      }
    }

    &-default {
      .mat-drawer-inner-container {
        @include set-width(panel, sidebar, default);

        .sidebar-layout {
          @include set-width(panel, sidebar, default);
        }
      }
    }

    &-full {
      .mat-drawer-inner-container {
        width: 100vw;
      }
    }
  }
}

@include max-tablet {
  .mat-drawer-container {
    &.sidebar-panel {

      &-big {
        .mat-drawer-inner-container {
          max-width: 90vw;

          .sidebar-layout {
            max-width: 90vw;
          }
        }
      }
    }
  }
}

@include max-mobile {
  .mat-drawer-container {
    &.sidebar-panel {
      &-default {
        .mat-drawer-inner-container {
          max-width: 90vw;

          .sidebar-layout {
            max-width: 90vw;
          }
        }
      }

      &-full {
        .mat-drawer {
          width: 100vw;
        }

        .mat-drawer-inner-container {
          min-width: 100vw;

          .sidebar-layout {
            min-width: 100vw;
          }
        }
      }
    }
  }
}