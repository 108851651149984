@use 'mixins' as *;

body {
  --mdc-protected-button-container-color: var(--default);

  .mat-step-icon {
    * {
      @include set-color(default);
    }

    &-content {
      &>* {
        position: absolute;
        -webkit-transform: translate(-50%, -52%);
        transform: translate(-50%, -52%);
      }
    }
  }

  .mat-icon {
    @include flex();

    &:not([class*=circle-size]) {
      width: 100%;
      height: 100%;
    }
  }

  .mat-mdc-form-field .mat-datepicker-toggle .mat-mdc-icon-button .mat-icon {
    font-size: 1rem;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
  }

  .mdc-button--raised:not([class*="padding"]) {
    padding: .375rem;
  }

  .mat-mdc-button .mdc-button__label,
  .mat-mdc-unelevated-button .mdc-button__label,
  .mat-mdc-raised-button .mdc-button__label,
  .mat-mdc-outlined-button .mdc-button__label {
    z-index: unset;
  }

  .mat-mdc-raised-button {
    &[class*=-disabled] {
      @include set-background-color(transparent);
    }

    .mdc-button__ripple {
      z-index: 2;
    }
  }

  .mdc-button {
    letter-spacing: normal;
  }

  // .mat-mdc-icon-button svg,
  // .mat-mdc-icon-button img {
  //   width: 100%;
  //   height: 100%;
  // }

  .mat-mdc-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  button,
  .cursor-pointer {
    cursor: pointer;

    // .mat-icon {
    //   display: flex;
    // }
  }

  .cursor-default {
    cursor: default;
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &:not([class*=border-]):not(:disabled):not(.inner-dot):not(.outer-dot):not(.outer-dot-odd) {
      @include set-border((color: selected));
    }
  }

  .mat-mdc-unelevated-button.mat-primary,
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-fab.mat-primary,
  .mat-mdc-mini-fab.mat-primary {
    @include set-background-color(selected);
    @include set-color(default);
    @include no-border();

    .mdc-button__label {
      @include set-color(default);
      @include set-background-color(transparent);
      @include space((padding: (x: .5, y: 0)));
    }
  }

  button,
  .mat-mdc-raised-button {
    @include flex();
    @include set-background-color(default);
    @include set-border-radius(button);
    min-width: auto;

    &.text-style {
      &-selected {

        .mdc-button__label {
          @include text(1.4, 2, 600);
          @include set-color(selected);
        }
      }
    }

    &:not(.mdc-switch) &:not([class*="padding-"]) {
      @include space((padding:(x:.5)));
    }

    &:not([class*=button-size-]) {
      &:not(.select-button) {
        &:not(.mat-mdc-icon-button) {
          &:not(.mat-calendar-body-cell) {
            &:not(.inner-dot):not(.outer-dot-odd) {
              @include set-height(button);
            }
          }
        }
      }
    }

    &:not(.mat-mdc-icon-button) {

      .mdc-button__label {
        @include flex();
        line-height: 1.75rem;

      }
    }

    .mdc-button__label:not(.custom) {
      @include set-background-color(transparent);
      white-space: nowrap;
    }

    // &.transparent-button {
    //   @include set-background-color(transparent);

    //   .mdc-button__label {
    //     @include set-background-color(transparent);
    //   }
    // }

    .button-content {
      @include flex();
      @include space(margin-left, .25);
      height: inherit;
      text-align: left;
    }

    &.select-button {
      &.search {
        @include set-height(selectButton, search);

        .mdc-button__label {
          @include text(1.125, 1.3125);
        }
      }

      &.big {
        @include set-height(selectButton, big);

        .mdc-button__label {
          @include text(1.125, 1.3125);
        }
      }

      &.default {
        @include set-height(selectButton, default);

        .mdc-button__label {
          @include text(0.875, 1);
        }
      }

      &:not(.active) {
        &:not(:disabled) {

          .button-content,
          .button-content * {
            @include set-color(selected);
          }
        }

        &:disabled {
          @include set-background-color(default);
          @include set-border();

          .button-content,
          .button-content * {
            @include set-color(border);
          }
        }
      }

      &.active {
        &.blocked {
          cursor: default;
        }

        &:not(:disabled) {
          @include set-background-color(selected);

          .button-content,
          .button-content * {
            @include set-color(default);
          }
        }

        &:disabled {
          box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
            0px 2px 2px 0px rgb(0 0 0 / 14%),
            0px 1px 5px 0px rgb(0 0 0 / 12%);

          @include set-background-color(border);
          @include set-border((radius: button));

          &:not(.loading) {

            .button-content,
            .button-content * {
              @include set-color(default);
            }
          }

          &.loading {

            .button-content,
            .button-content * {
              @include set-color(selected);
            }
          }
        }
      }

      &[color="primary"] {
        &:disabled {
          .mdc-button__label {
            @include set-color(textInactive);
            @include set-background-color(transparent);

            .mat-icon {
              @include set-color(textInactive);
              @include space(margin-right, .125);
            }
          }
        }

        &:not(:disabled) {
          .mdc-button__label {
            @include set-color(default);
            @include set-background-color(transparent);

            .mat-icon {
              @include set-color(default);
              @include space(margin-right, .125);
            }
          }
        }

      }
    }

    &.white-button {
      @include no-border();
      @include space((padding:(x: .25)));

      text-transform: uppercase;
      font-weight: 700;
      height: min-content;

      .mdc-button__label {
        line-height: 1.25rem;
      }
    }
  }

  .select-icon {
    &:not(.active) {
      &:not(:disabled) {
        .mat-icon {
          @include set-path($stroke: formControl, $fill: default);
        }
      }

      &:disabled {
        .mat-icon {
          @include set-path($stroke: border, $fill: default);
        }
      }
    }

    &.active {
      &[size=default] {
        @include set-size(icon, default);
      }

      &:not(.bc-none) {
        .mat-icon {
          @include set-path($fill: default, $children: 1);
          @include set-path($stroke: default, $fill: none, $children: 2);
        }
      }

      &.bc-none {
        .mat-icon {
          @include set-path($fill: selected, $children: 1);
          @include set-path($stroke: selected, $fill: none, $children: 2);
        }
      }
    }
  }

  .checkbox-icon {
    .mat-icon {
      cursor: pointer;
    }

    &:not(.active) {
      .mat-icon {
        @include set-path($stroke: formControl, $fill: default);
      }
    }

    &.active {
      .mat-icon {
        @include set-path($fill: textPrimary, $children: 1);
        @include set-path($stroke: formControl, $fill: none, $children: 2);
      }
    }
  }
}