@use 'mixins' as *;

html,
body {

  --mdc-outlined-text-field-input-text-placeholder-color: #0000006b;

  .mat-pseudo-checkbox {
    display: none;
  }

  .mat-mdc-form-field {
    width: 100%;

    &.disabled {
      .mdc-notched-outline__trailing {
        @include set-background-color(border);
      }
    }

    &:not(.disabled) {
      .mdc-notched-outline__trailing {
        @include set-background-color(default);
      }
    }

    &-subscript-wrapper {
      // top: unset;
      // padding: 0;
      display: none;
    }

    .mat-mdc-text-field-wrapper {
      @include padding-less();
      @include margin-less(important);

      .mat-mdc-form-field-flex {
        @include set-height(input);
        @include margin-less(important);

        top: 0;
        padding: 0 0 0 .25rem !important;
        display: inline-flex;
        align-items: center;

        .mdc-notched-outline {
          top: 0;

          .mdc-notched-outline__leading {
            display: none;
          }

          .mdc-notched-outline__trailing {
            @include set-border((color: formControl));
          }
        }

        .mat-mdc-form-field-infix,
        .mat-mdc-form-field-prefix {
          z-index: 1;
          top: 0;
        }

        .mat-mdc-form-field-infix {
          @include flex(flex-start);
          border-top-width: 0;
          padding: 0 0.4rem;
          width: 100% !important;

          [matpostfix] {
            @include relative((right: -.3rem));

            img {
              left: auto;
            }
          }

          input {
            @include three-dots();

            &:disabled {
              @include set-color(textInactive);
            }
          }

          .mat-mdc-select-arrow-wrapper {
            @include relative((height: 0));
            // right: 0.4rem;
          }
        }

        .mat-mdc-form-field-icon-prefix,
        .mat-mdc-form-field-suffix {
          top: 0;
          padding: 0;
        }
      }
    }

    &.textarea {

      .mat-mdc-form-field-flex {
        height: auto;

        .mat-mdc-form-field-infix {
          padding: .5rem;
        }
      }

      textarea {
        @include set-min-height(textarea, default, min);
        @include set-max-height(textarea, default, max);
      }
    }
  }
}
