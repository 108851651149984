@use 'sass:string';
@use 'mixins' as *;

@mixin setup-all-screens() {
  @each $item in map-keys(width(screen)) {
    #{"&-"+ $item + "-max"} {
      @include for-screen-max($item) {
        @content;
      }
    }

    #{"&-"+ $item + "-min"} {
      @include for-screen-min($item) {
        @content;
      }
    }
  }
}

@mixin setup-svg-colors() {
  @each $color in map-keys($colors) {
    @if(type-of(map-get($colors, $color))=='string') {
      //@debug $color +(str-index(#{$color}, 'scrollbar'));

      .stroked {
        &#{"-"+ $color} {
          @include set-path($stroke: $color);
        }

        &#{"-rect"+ $color} {
          @include set-rect($stroke: $color);
        }

        &#{"-path"+ $color} {
          @include set-path($stroke: $color);
        }

        &#{"-circle-"+ $color} {
          @include set-circle($stroke: $color);
        }
      }

      .filled {
        &#{"-"+ $color} {
          @include set-path($fill: $color);
        }

        &#{"-rect-"+ $color} {
          @include set-rect($fill: $color);
        }

        &#{"-path-"+ $color} {
          @include set-path($fill: $color);
        }

        &#{"-circle-"+ $color} {
          @include set-circle($fill: $color);
        }
      }
    }
  }
}

@mixin setup-color() {
  @each $color in map-keys($colors) {
    @if(type-of(map-get($colors, $color))=='string') {
      #{".color-"+ $color} {
        @include set-color($color);
      }

      #{".bc-"+ $color} {
        @include set-background-color($color);
      }

      @if($color ==border) {
        .border {
          @include set-border();

          &-top {
            @include set-border((), top);
          }

          &-left {
            @include set-border((), left);
          }
        }
      }

      @else {
        #{".border-"+ $color} {
          @include set-border((color: $color));

          &-double {
            @include set-border((color: $color, width: double));
          }

          &-top {
            @include set-border((color: $color), top);
          }

          &-left {
            @include set-border((color: $color), left);
          }

          &-bottom {
            @include set-border((color: $color), bottom);

            &-dotted {
              @include set-border((color: $color, style: dotted), bottom);
            }

            &-double {
              @include set-border((color: $color, width: 'double'), bottom);
            }
          }
        }
      }
    }
  }
}

@mixin setup-icons-sizes() {
  @each $size in map-keys(size(icon)) {
    #{".circle-size-" + $size} {
      border-radius: 50%;
      @include set-size(icon, $size);
    }

    #{".icon-size-" + $size} {
      svg {
        @include set-size(icon, $size);
      }
    }
  }
}

@mixin setup-buttons-sizes() {
  @each $size in map-keys(size(button)) {
    .mat-mdc-icon-button.mat-mdc-button-base#{".button-size-" + $size} {
      @include set-size(button, $size);
      font: size($size);
    }
  }
}

@mixin for-screen-max($screen) {
  @media screen and (max-width: calc(width(screen, $screen) - 0.1px)) {
    @content;
  }
}

@mixin for-screen-min($screen) {
  @media screen and (min-width: width(screen, $screen)) {
    @content;
  }
}

@mixin setup-button-space() {
  $sides: (
    left,
    right,
    bottom,
    top
  );

@each $spaceKey in map-keys(margin(button)) {
  @each $side in $sides {
    $spaceSuffix: -#{$spaceKey};

    @if($spaceKey ==default) {
      $spaceSuffix: ''
    }

    .bs-#{$side}#{$spaceSuffix} {
      //bs === button space
      @include icon-button-space($spaceKey, $side);
    }
  }
}
}

@mixin setup-text-old() {
  $from: 0.5;
  $to: 2.5;
  $step: 0.1;
  $fontSize: $from;
  $lineHeight: $from;

  $fontWeightStep: 100;
  $fontWeightMax: 800;
  $fontWeight: $fontWeightStep;

  @while $to >$fontSize {
    @while $to >$lineHeight {

      @while $fontWeight <=$fontWeightMax {
        $suffix: str-replace(#{$fontSize}-#{$lineHeight}-#{$fontWeight},
            '.',
            "_");

        .text-#{$suffix} {
          @include text($fontSize, $lineHeight, $fontWeight);
        }

        $fontWeight: $fontWeight + $fontWeightStep;
      }

      $fontWeight: $fontWeightStep;
      $lineHeight: $lineHeight + $step;
    }

    $lineHeight: $from;
    $fontSize: $fontSize + $step;
  }
}

@mixin setup-hidden-all-screens() {
  .hidden {
    @include setup-all-screens() {
      display: none !important;
    }
  }
}

@mixin setup-all-space-sizes($space) {
  @each $spaceSize in map-keys($spaceSizes) {
    $value: space-size($spaceSize);

    #{"&-" + $spaceSize} {
      @include space($space, $value);
    }
  }
}

@mixin setup-space-all-settings($space) {
  #{"." + $space} {
    @include space($space);

    @include setup-all-space-sizes($space);

    @each $direction in $spaceDirections {
      #{"&-" + $direction} {

        @include space(($space: ($direction: 1)));

        @each $spaceSize in map-keys($spaceSizes) {
          $value: space-size($spaceSize);

          #{"&-" + $spaceSize} {
            @include space(($space: ($direction: $value)));
          }
        }
      }
    }
  }
}

@mixin setup-text() {
  .text {
    @each $font-size in $fontSizes {
      $font-size-name: str-replace(#{$font-size}, ".", "_");

      #{"&-" + $font-size-name} {

        @each $line-height in $lineHeights {
          $line-height-name: str-replace(#{$line-height}, ".", "_");

          #{"&-" + $line-height-name} {

            @each $font-weight in $fontWeights {
              $font-weight-name: str-replace(#{$font-weight}, ".", "_");

              #{"&-" + $font-weight-name} {
                @include text($font-size, $line-height, $font-weight);
              }
            }
          }
        }
      }
    }
  }
}