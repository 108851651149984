@use 'mixins' as *;

body {
  ::-webkit-scrollbar {
    width: width(scrollBar) * 2;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background-clip: content-box;
    //border-left: width(scrollBar) solid transparent;
    @include set-background-color(scrollbar, track);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-clip: content-box;
    //border-left: width(scrollBar) solid transparent;
    @include set-background-color(scrollbar, thumb);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @include set-background-color(scrollbar, thumbHover);
  }
}