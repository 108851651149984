@use 'sass:math';
@use 'nested';

$colors: (
  none: none,
  transparent: var(--transparent),

  border: var(--border),
  default: var(--default),
  selected: var(--selected),
  background: var(--background),
  highlighted: var(--highlighted),
  highlightedText: var(--highlighted-text),
  emphasized: var(--emphasized),

  header: var(--header),
  headerText: var(--header-text),
  amenitiesBar: var(--amenities-bar),
  searchBar: var(--search-bar),
  boxShadow: var(--box-shadow),
  formControl: var(--form-control),
  ratePlanType: var(--rate-plan-type),
  optionBackground: var(--option-background),

  successInfoLabel: var(--success-info-label),
  successText: var(--success-text),
  successBackground: var(--success-background),

  textError: var(--text-error),
  textPrimary: var(--text-primary),
  textInactive: var(--text-inactive),
  textFlightJourney: var(--text-flight-journey),
  textSuccessPrice: var(--text-success-price),

  iconFill: var(--icon-fill),
  iconStroke: var(--icon-stroke),
  iconFlight: var(--icon-flight),
  iconInactive: var(--icon-inactive),

  scrollbar: (track: var(--scrollbar-track),
    thumb: var(--scrollbar-thumb),
    thumbHover: var(--scrollbar-thumb-hover)),

  required: var(--required),
  notFound: var(--not-found),

  finalPrice: var(--final-price),
  basePrice: var(--base-price),

  alertBackground: var(--alert-background),
  alertBorder: var(--alert-border),
  alertText: var(--alert-text),

  warningBackground: var(--warning-background),
  warningBorder: var(--warning-border),
  warningText: var(--warning-text),

  notificationBackground: var(--notification-background),
  notificationBorder: var(--notification-border),
  notificationIcon: var(--notification-icon),

  memberBannerBackground: var(--member-banner-background),
  memberDefault: var(--member-default),
  memberText: var(--member-text),

  modalBackground: var(--modal-background)
);

$paddings: (
  mainContainer: (desktop: 2.5%, mobile: 2.5%),
  contentContainer: (desktop: 2.5%, mobile: 0px),
  default: 1rem,
);

$sizes: (
  icon:(small: 1rem, default: 1.25rem, middle: 1.5rem, big: 1.75rem, huge: 2.5rem),
  button: (small: 1rem, radio: 1.25rem, middle: 1.5rem, default: 2rem, calendar: 2.5rem, big: 3rem),
  slider: (default: 1rem, container: 1.25rem),
  loader: (window: 4rem, button: 1.5rem)
);

$mobileAirlaneSelectWidth: 13rem;
$searchBarSpace: 0.4rem;
$searchBarContainerWidth: calc($mobileAirlaneSelectWidth * 2 + $searchBarSpace);

$margins: (
  button: (default: -(math.div(nested.nested-map-get($sizes, button, default), 4))),
  default: 1rem,
  container: 1.5rem
);

$widths: (
  page: (default: 1280px, narrow: 800px),
  screen:(mobile: 480px, ipod: 640px, tablet: 768px, desktop: 1280px),
  panel: (sidebar: (default: 23rem, big: 45rem)),
  scrollBar: .5rem,
  dateRangePicker: (desktop: 23rem, mobile: $searchBarContainerWidth),
  searchBarPicker: (desktop: 20.625rem, mobile: $searchBarContainerWidth),
  border: (default: 1px, double: 2px, thick: 3px),
  max: (mobile: calc(100vw - (2 * nested.nested-map-get($paddings, mainContainer, mobile)))),
  min: (mobile: 20rem),
  sliderContainer: 2.5rem,
  airportAutoComplete: (desktop: $searchBarContainerWidth,
    mobile: $mobileAirlaneSelectWidth,
    input: calc(100% - nested.nested-map-get($sizes, button, default))),
  option: 17rem,
  resortLogo: 7.8125rem
);

$heights: (
  header: (default: 3rem, withSwitcher: 8.75rem),
  input: 2.75rem,
  button: 2rem,
  searchInput: 2.5rem,
  searchBar: (desktop: 4rem, mobile: 6.88rem),
  sidebarPhoto: 22rem,
  option: (photo: 10rem, card: 20rem),
  selectButton: (default: 2.25rem, search: 2.5rem, big: 3.25rem),
  collapsed: 1.75rem,
  room: (photo:(desktop: 28rem, mobile: 14rem), details:(mobile: 23rem)),
  textarea: (default:(min: 4rem, max: 10rem)),
  resortLogo: 2.75rem
);

$border-radiuses: (
  default: 0.125rem,
  button: 0.125rem,
  infoLabel: 0.25rem
);

$z-indexes: (
  default: 1,
  backdrop: 2,
  popup: 3,
  select: 4,
  sidebar: 5,
);

$spaces: (
  default: 1rem,
  searchBar: $searchBarSpace
);

$spaceSizes: (
  default: 1,
  less: 0,
  quarter: .25,
  half: .5,
  double: 2,
  0_75: .75,
  auto: auto,
  minus: -1,
  minus-half: -.5
);

$spaceDirections: (
  top,
  right,
  bottom,
  left,
  horizontal,
  vertical
);

$fontSizes: (
  .625,
  .75,
  .875,
  1,
  1.125,
  1.25,
  1.375,
  1.5,
  1.75,
  2
);

$lineHeights: (
  .75,
  .875,
  1,
  1.125,
  1.25,
  1.375,
  1.5,
  1.75,
  2
);

$fontWeights: (
  400,
  500,
  600
);