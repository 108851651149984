@use 'mixins' as *;

html,
body {

  --mat-select-container-elevation-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  --mat-autocomplete-container-elevation-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  --mat-select-placeholder-text-color: #0000006b;

  .mat-mdc-select {
    display: flex;
  }

  div.mat-mdc-select-panel,
  div.mat-mdc-autocomplete-panel {
    @include set-background-color(default);
    @include space(padding-top, 0);
  }

  .mat-mdc-option {
    .mdc-list-item__primary-text {
      @include text(1.125, 1.125, 400);
      font-family: Work Sans;
      // display: inline-block;
      // flex-grow: 1;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      // letter-spacing: normal
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      @include set-color(selected);
    }

    &.separator {
      @include set-border((), bottom);
      @include space(margin, .25);
      height: .01rem;
      min-height: .01rem;
    }
  }
}