@use 'mixins' as *;

html,
body {
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, .2);

  input[type="datetime-local"] {
    cursor: pointer;

    &::after {
      content: url(/assets/icons/calendar.svg) !important;
      height: 1.5rem;
      width: 1.5rem !important;
    }
  }

  .cdk-overlay-backdrop {
    background: rgba(0, 0, 0, .6);
  }

  .mat-calendar-body-disabled {
    @include set-color(textInactive);
  }

  .mat-datepicker {
    &-popup {
      @include centered();
      bottom: auto !important;
    }

    &-0-backdrop {
      transition-duration: 400ms;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: background-color, visibility;
      background-color: rgba(0, 0, 0, .6);
    }

    &-toggle {
      .mat-mdc-icon-button.mat-mdc-button-base {
        @include set-size(button, default);
      }

      .mdc-button__label {
        @include set-border-radius(default);
      }
    }

    &-popup {
      @include set-width(dateRangePicker, desktop);

      .mat-datepicker-content {
        width: 100%;

        .mat-calendar {
          @include set-background-color(default);
          @include full-size-important();

          &-controls {
            @include space(margin, .25);
          }

          &-previous-button,
          &-next-button {
            @include set-size(button, calendar);
          }

          &-body {

            &-range-end::after,
            &-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
            &-comparison-end::after,
            &-preview-end .mat-calendar-body-cell-preview {
              border-radius: inherit;
            }

            &-range-start,
            &-range-end {
              width: 120%;
            }

            &-range-start::before {
              background-color: transparent
            }

            &-cell {
              height: 70%;
            }

            &-cell-content {
              border: none;
            }

            &-selected {
              @include set-background-color(selected);
              @include text(1.125, 1.125, 500);
              @include set-color(default);
              border-radius: inherit;
            }

            tr:nth-of-type(1) {
              td {
                padding: 1.5rem !important
              }
            }
          }
        }
      }
    }
  }

  .mat-date-range-input-wrapper input {
    width: 102%;
  }

  .mat-toolbar.mat-primary {
    @include set-background-color(default);

    .time-frame {
      .time {
        opacity: 1;

        &.select {
          @include set-color(selected);
        }

        &:not(.select) {
          @include set-color(textPrimary);
        }
      }
    }

    .ampm {
      .time {
        opacity: 1;

        &.select {
          @include set-color(selected);
        }
      }
    }
  }

  @include max-tablet {
    .mat-datepicker-popup {
      @include set-max-width(dateRangePicker, mobile);
      width: 100%;
    }
  }

  @media screen and (max-width: width(dateRangePicker, mobile)) {
    .mat-datepicker-popup {
      padding-left: padding(mainContainer, mobile);
      padding-right: padding(mainContainer, mobile);
      //left: 0 !important;
      right: 0 !important;
    }
  }
}