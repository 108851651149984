@use 'mixins' as *;

body {
  .height {
    &-less {
      height: 0;
    }
  }

  .collapsable {
    overflow: hidden;
    height: auto;
    max-height: 9999px;
    transition: max-height 0.4s cubic-bezier(1, 0, 1, 0);
  }

  .collapsed {
    max-height: 0;
    padding: 0 !important;
    transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);
  }

  .position-relative {
    @include relative();
  }

  .box-shadow {
    box-shadow: 0px 1px 4px color(boxShadow);

    &-container {
      padding: 0px 3px 3px 2px;
    }
  }

  .divider-dotted {
    @include space((margin: (vertical)));
    @include set-border((color:formControl, style: dashed), bottom);
  }

  .w {
    &-100 {
      width: 100%;
    }

    &-50 {
      width: 50%;
    }
  }

  .h {
    &-100 {
      height: 100%;

      &-min {
        min-height: 100%;
      }
    }

    &-50 {
      height: 50%;
    }
  }

  .full-size {
    width: 100%;
    height: 100%;
  }

  .full-width {
    width: 100%;

    &-plus-padding {
      width: calc(100% + padding(default));

      &-double {
        width: calc(100% + (padding(default) * 2));
      }
    }

  }

  .border-width {
    &-thick {
      border-width: width(border, thick);
    }

    &-default {
      border-width: width(border, default);
    }
  }

  .not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .centered {
    @include centered();
  }

  .resort-logo {
    max-width: 33%;

    img {
      //@include set-max-width(resortLogo);
      @include set-max-height(resortLogo);
      height: 100%;
      width: 100%;
    }
  }

  .cdk-virtual-scroll-orientation-horizontal .cdk-virtual-scroll-content-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .overflow {
    &-hidden {
      overflow: hidden;

      &-x {
        overflow-x: hidden;
      }

      &-scroll {
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }

    &-auto {
      overflow: auto;
    }
  }
}

.block {
  display: block;
}

.list-item {
  display: list-item;
}