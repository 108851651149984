@use 'mixins' as *;

html,
body {

  // to fix overflow for addons
  .cdk-virtual-scroll-spacer {
    width: auto !important;
  }

  .mat-expansion-panel {
    @include set-background-color(default);

    &:not([class*=mat-elevation-z]) {
      box-shadow: none;

      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }


  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: #0009
  }


  .mat-mdc-autocomplete-panel {
    @include set-border-radius(default);
  }
}