@use 'mixins' as *;
@use 'setup' as *;

body {
  .flex {
    @include flex();

    &-1 {
      flex: 1;
    }

    &-100 {
      flex: 1;
      height: 100%;
      width: 100%;
    }

    &-stretch {
      @include flex(stretch);

      &-stretch {
        @include flex(stretch, stretch);
      }
    }

    &-center {
      @include flex();

      &-start {
        @include flex(center, flex-start);
      }

      &-between {
        @include flex(center, space-between);
      }

      &-stretch {
        @include flex(center, stretch);
      }

      &-center {
        @include flex(center, center);
      }

      &-end {
        @include flex(center, flex-end);
      }
    }

    &-start {
      @include flex(flex-start);

      &-start {
        @include flex(flex-start, flex-start);
      }
    }

    &-end {
      @include flex(flex-end);
    }

    &-between {
      @include flex(space-between);

      &-start {
        @include flex(space-between, flex-start);
      }

      &-end {
        @include flex(space-between, flex-end);
      }

      &-center {
        @include flex(space-between, center);
      }
    }

    &-column {
      @include flex-column();

      @include setup-all-screens {
        @include flex-column;
      }

      // &-mobile {
      //   &-max {
      //     @include max-mobile {
      //       @include flex-column();
      //     }
      //   }
      // }

      // &-tablet {
      //   &-min {
      //     @include min-tablet {
      //       @include flex-column();
      //     }
      //   }

      //   &-max {
      //     @include max-tablet {
      //       @include flex-column();
      //     }
      //   }
      // }

      // &-desktop {
      //   &-min {
      //     @include min-desktop {
      //       @include flex-column();
      //     }
      //   }

      //   &-max {
      //     @include max-desktop {
      //       @include flex-column();
      //     }
      //   }
      // }
    }

    &-wrap {
      flex-wrap: wrap;
    }

    &-nowrap {
      flex-wrap: nowrap;
    }
  }

  .justify {
    &-center {
      justify-content: center;
    }

    &-start {
      justify-content: flex-start;
    }
  }

  .self {
    &-align {
      &-center {
        align-self: center;
      }

      &-start {
        align-self: flex-start;
      }
    }
  }

  .gap {
    @include space(gap);
    @include setup-all-space-sizes(gap);

    @include setup-all-screens() {
      @include space(gap);
    }
  }
}