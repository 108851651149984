body {

  .hidden,
  *[hidden] {
    display: none !important;
  }

  .hide-if-empty:empty {
    display: none;
  }
}