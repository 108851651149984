@use 'mixins' as *;

body {
  --mdc-switch-unselected-hover-track-color: gray;

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch .mdc-switch__track {
    @include set-size-height(slider, container);
    @include set-width(sliderContainer);
    @include set-color(transparent);
    border-radius: 1rem;
  }

  .mdc-switch:enabled .mdc-switch__track::before,
  .mdc-switch:enabled:active .mdc-switch__track::before,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
    @include set-background-color(border);
  }

  .mdc-switch .mdc-switch__handle-track {
    // @include set-size(slider, default);
    // top: calc(size(slider, default) / 2);
    left: calc(size(slider, default) / 4);
  }

  .mat-mdc-slide-toggle .mdc-switch__handle:has(.mdc-switch__icons),
  .mdc-switch .mdc-switch__focus-ring-wrapper,
  .mdc-switch .mdc-switch__handle,
  .mat-mdc-slide-toggle .mdc-switch:active:not(.mdc-switch--disabled) .mdc-switch__handle {
    @include set-size(slider, default);
    border-radius: 50%;
  }

  .mdc-switch:enabled .mdc-switch__track::after,
  .mdc-switch:enabled:active .mdc-switch__track::after,
  .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
  .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
    @include set-background-color(selected);
  }

  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
    @include set-background-color(default);
  }

  .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
  .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
    @include set-background-color(default);
  }
}