html {
  --mat-sidenav-container-width: auto;
}

.mat-drawer-container {
  height: 100%;
}
.mat-drawer-container .mat-drawer {
  z-index: 5;
}
.mat-drawer-container .mat-drawer-content {
  position: static;
}
.mat-drawer-container.only-backdrop .mat-drawer {
  display: none;
}
.mat-drawer-container .mat-drawer-content {
  background-color: var(--background);
}
.mat-drawer-container .mat-drawer-inner-container {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
}
.mat-drawer-container .mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.mat-drawer-container.sidebar-panel-big .mat-drawer-inner-container {
  width: 45rem;
}
.mat-drawer-container.sidebar-panel-big .mat-drawer-inner-container .sidebar-layout {
  width: 45rem;
}
.mat-drawer-container.sidebar-panel-default .mat-drawer-inner-container {
  width: 23rem;
}
.mat-drawer-container.sidebar-panel-default .mat-drawer-inner-container .sidebar-layout {
  width: 23rem;
}
.mat-drawer-container.sidebar-panel-full .mat-drawer-inner-container {
  width: 100vw;
}

@media screen and (max-width: 767.9px) {
  .mat-drawer-container.sidebar-panel-big .mat-drawer-inner-container {
    max-width: 90vw;
  }
  .mat-drawer-container.sidebar-panel-big .mat-drawer-inner-container .sidebar-layout {
    max-width: 90vw;
  }
}
@media screen and (max-width: 479.9px) {
  .mat-drawer-container.sidebar-panel-default .mat-drawer-inner-container {
    max-width: 90vw;
  }
  .mat-drawer-container.sidebar-panel-default .mat-drawer-inner-container .sidebar-layout {
    max-width: 90vw;
  }
  .mat-drawer-container.sidebar-panel-full .mat-drawer {
    width: 100vw;
  }
  .mat-drawer-container.sidebar-panel-full .mat-drawer-inner-container {
    min-width: 100vw;
  }
  .mat-drawer-container.sidebar-panel-full .mat-drawer-inner-container .sidebar-layout {
    min-width: 100vw;
  }
}
body {
  --mdc-switch-unselected-hover-track-color: gray;
}
body .mdc-switch__icons {
  display: none;
}
body .mdc-switch .mdc-switch__track {
  height: 1.25rem;
  width: 2.5rem;
  color: var(--transparent);
  border-radius: 1rem;
}
body .mdc-switch:enabled .mdc-switch__track::before,
body .mdc-switch:enabled:active .mdc-switch__track::before,
body .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::before {
  background-color: var(--border);
}
body .mdc-switch .mdc-switch__handle-track {
  left: 0.25rem;
}
body .mat-mdc-slide-toggle .mdc-switch__handle:has(.mdc-switch__icons),
body .mdc-switch .mdc-switch__focus-ring-wrapper,
body .mdc-switch .mdc-switch__handle,
body .mat-mdc-slide-toggle .mdc-switch:active:not(.mdc-switch--disabled) .mdc-switch__handle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
}
body .mdc-switch:enabled .mdc-switch__track::after,
body .mdc-switch:enabled:active .mdc-switch__track::after,
body .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after,
body .mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after {
  background-color: var(--selected);
}
body .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
body .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after,
body .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
body .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background-color: var(--default);
}
body .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after,
body .mdc-switch.mdc-switch--unselected:enabled:active .mdc-switch__handle::after,
body .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
body .mdc-switch.mdc-switch--unselected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background-color: var(--default);
}

body .mat-mdc-radio-button .mdc-radio {
  padding: 0;
}
body .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: var(--icon-inactive);
}
body .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before,
body .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: transparent;
}
body .mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before,
body .mat-mdc-radio-button .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
body .mat-mdc-radio-button .mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
body .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
body .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
body .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
body .mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
body .mat-mdc-radio-button .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle,
body .mat-mdc-radio-button .mdc-radio:not(.mdc-ripple-upgraded):focus .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
body .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
body .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--selected);
}
body .mat-radio-label {
  align-items: flex-start;
}

html,
body {
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(63, 81, 181, .2);
}
html input[type=datetime-local],
body input[type=datetime-local] {
  cursor: pointer;
}
html input[type=datetime-local]::after,
body input[type=datetime-local]::after {
  content: url(/assets/icons/calendar.svg) !important;
  height: 1.5rem;
  width: 1.5rem !important;
}
html .cdk-overlay-backdrop,
body .cdk-overlay-backdrop {
  background: rgba(0, 0, 0, 0.6);
}
html .mat-calendar-body-disabled,
body .mat-calendar-body-disabled {
  color: var(--text-inactive);
}
html .mat-datepicker-popup,
body .mat-datepicker-popup {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  bottom: auto !important;
}
html .mat-datepicker-0-backdrop,
body .mat-datepicker-0-backdrop {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: background-color, visibility;
  background-color: rgba(0, 0, 0, 0.6);
}
html .mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base,
body .mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
  width: 2rem;
  height: 2rem;
}
html .mat-datepicker-toggle .mdc-button__label,
body .mat-datepicker-toggle .mdc-button__label {
  border-radius: 0.125rem;
}
html .mat-datepicker-popup,
body .mat-datepicker-popup {
  width: 23rem;
}
html .mat-datepicker-popup .mat-datepicker-content,
body .mat-datepicker-popup .mat-datepicker-content {
  width: 100%;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar {
  background-color: var(--default);
  width: 100% !important;
  height: 100% !important;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-controls,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-controls {
  margin: 0.25rem;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-previous-button, html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-next-button,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-previous-button,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-next-button {
  width: 2.5rem;
  height: 2.5rem;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-end::after, html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before, html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-comparison-end::after, html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-preview-end .mat-calendar-body-cell-preview,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-end::after,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-comparison-end::after,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
  border-radius: inherit;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-start, html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-end,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-start,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-end {
  width: 120%;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-start::before,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-range-start::before {
  background-color: transparent;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-cell,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-cell {
  height: 70%;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-cell-content,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-cell-content {
  border: none;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-selected,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body-selected {
  background-color: var(--selected);
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 500;
  color: var(--default);
  border-radius: inherit;
}
html .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body tr:nth-of-type(1) td,
body .mat-datepicker-popup .mat-datepicker-content .mat-calendar-body tr:nth-of-type(1) td {
  padding: 1.5rem !important;
}
html .mat-date-range-input-wrapper input,
body .mat-date-range-input-wrapper input {
  width: 102%;
}
html .mat-toolbar.mat-primary,
body .mat-toolbar.mat-primary {
  background-color: var(--default);
}
html .mat-toolbar.mat-primary .time-frame .time,
body .mat-toolbar.mat-primary .time-frame .time {
  opacity: 1;
}
html .mat-toolbar.mat-primary .time-frame .time.select,
body .mat-toolbar.mat-primary .time-frame .time.select {
  color: var(--selected);
}
html .mat-toolbar.mat-primary .time-frame .time:not(.select),
body .mat-toolbar.mat-primary .time-frame .time:not(.select) {
  color: var(--text-primary);
}
html .mat-toolbar.mat-primary .ampm .time,
body .mat-toolbar.mat-primary .ampm .time {
  opacity: 1;
}
html .mat-toolbar.mat-primary .ampm .time.select,
body .mat-toolbar.mat-primary .ampm .time.select {
  color: var(--selected);
}
@media screen and (max-width: 767.9px) {
  html .mat-datepicker-popup,
  body .mat-datepicker-popup {
    max-width: 26.4rem;
    width: 100%;
  }
}
@media screen and (max-width: 26.4rem) {
  html .mat-datepicker-popup,
  body .mat-datepicker-popup {
    padding-left: 2.5%;
    padding-right: 2.5%;
    right: 0 !important;
  }
}

body {
  --mdc-protected-button-container-color: var(--default);
}
body .mat-step-icon * {
  color: var(--default);
}
body .mat-step-icon-content > * {
  position: absolute;
  -webkit-transform: translate(-50%, -52%);
  transform: translate(-50%, -52%);
}
body .mat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .mat-icon:not([class*=circle-size]) {
  width: 100%;
  height: 100%;
}
body .mat-mdc-form-field .mat-datepicker-toggle .mat-mdc-icon-button .mat-icon {
  font-size: 1rem;
}
body .mat-mdc-icon-button.mat-mdc-button-base {
  padding: 0;
}
body .mdc-button--raised:not([class*=padding]) {
  padding: 0.375rem;
}
body .mat-mdc-button .mdc-button__label,
body .mat-mdc-unelevated-button .mdc-button__label,
body .mat-mdc-raised-button .mdc-button__label,
body .mat-mdc-outlined-button .mdc-button__label {
  z-index: unset;
}
body .mat-mdc-raised-button[class*=-disabled] {
  background-color: var(--transparent);
}
body .mat-mdc-raised-button .mdc-button__ripple {
  z-index: 2;
}
body .mdc-button {
  letter-spacing: normal;
}
body .mat-mdc-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
body button,
body .cursor-pointer {
  cursor: pointer;
}
body .cursor-default {
  cursor: default;
}
body .mat-mdc-unelevated-button:not([class*=border-]):not(:disabled):not(.inner-dot):not(.outer-dot):not(.outer-dot-odd),
body .mat-mdc-raised-button:not([class*=border-]):not(:disabled):not(.inner-dot):not(.outer-dot):not(.outer-dot-odd),
body .mat-mdc-fab:not([class*=border-]):not(:disabled):not(.inner-dot):not(.outer-dot):not(.outer-dot-odd),
body .mat-mdc-mini-fab:not([class*=border-]):not(:disabled):not(.inner-dot):not(.outer-dot):not(.outer-dot-odd) {
  border: 1px solid var(--selected);
  border-radius: 0.125rem;
}
body .mat-mdc-unelevated-button.mat-primary,
body .mat-mdc-raised-button.mat-primary,
body .mat-mdc-fab.mat-primary,
body .mat-mdc-mini-fab.mat-primary {
  background-color: var(--selected);
  color: var(--default);
  border: none;
}
body .mat-mdc-unelevated-button.mat-primary .mdc-button__label,
body .mat-mdc-raised-button.mat-primary .mdc-button__label,
body .mat-mdc-fab.mat-primary .mdc-button__label,
body .mat-mdc-mini-fab.mat-primary .mdc-button__label {
  color: var(--default);
  background-color: var(--transparent);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}
body button,
body .mat-mdc-raised-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--default);
  border-radius: 0.125rem;
  min-width: auto;
}
body button.text-style-selected .mdc-button__label,
body .mat-mdc-raised-button.text-style-selected .mdc-button__label {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  color: var(--selected);
}
body button:not(.mdc-switch) body button:not([class*=padding-]),
body button:not(.mdc-switch) body .mat-mdc-raised-button:not([class*=padding-]),
body .mat-mdc-raised-button:not(.mdc-switch) body button:not([class*=padding-]),
body .mat-mdc-raised-button:not(.mdc-switch) body .mat-mdc-raised-button:not([class*=padding-]) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
body button:not([class*=button-size-]):not(.select-button):not(.mat-mdc-icon-button):not(.mat-calendar-body-cell):not(.inner-dot):not(.outer-dot-odd),
body .mat-mdc-raised-button:not([class*=button-size-]):not(.select-button):not(.mat-mdc-icon-button):not(.mat-calendar-body-cell):not(.inner-dot):not(.outer-dot-odd) {
  height: 2rem;
}
body button:not(.mat-mdc-icon-button) .mdc-button__label,
body .mat-mdc-raised-button:not(.mat-mdc-icon-button) .mdc-button__label {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.75rem;
}
body button .mdc-button__label:not(.custom),
body .mat-mdc-raised-button .mdc-button__label:not(.custom) {
  background-color: var(--transparent);
  white-space: nowrap;
}
body button .button-content,
body .mat-mdc-raised-button .button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.25rem;
  height: inherit;
  text-align: left;
}
body button.select-button.search,
body .mat-mdc-raised-button.select-button.search {
  height: 2.5rem;
}
body button.select-button.search .mdc-button__label,
body .mat-mdc-raised-button.select-button.search .mdc-button__label {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: 400;
}
body button.select-button.big,
body .mat-mdc-raised-button.select-button.big {
  height: 3.25rem;
}
body button.select-button.big .mdc-button__label,
body .mat-mdc-raised-button.select-button.big .mdc-button__label {
  font-size: 1.125rem;
  line-height: 1.3125rem;
  font-weight: 400;
}
body button.select-button.default,
body .mat-mdc-raised-button.select-button.default {
  height: 2.25rem;
}
body button.select-button.default .mdc-button__label,
body .mat-mdc-raised-button.select-button.default .mdc-button__label {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 400;
}
body button.select-button:not(.active):not(:disabled) .button-content,
body button.select-button:not(.active):not(:disabled) .button-content *,
body .mat-mdc-raised-button.select-button:not(.active):not(:disabled) .button-content,
body .mat-mdc-raised-button.select-button:not(.active):not(:disabled) .button-content * {
  color: var(--selected);
}
body button.select-button:not(.active):disabled,
body .mat-mdc-raised-button.select-button:not(.active):disabled {
  background-color: var(--default);
  border: 1px solid var(--border);
  border-radius: 0.125rem;
}
body button.select-button:not(.active):disabled .button-content,
body button.select-button:not(.active):disabled .button-content *,
body .mat-mdc-raised-button.select-button:not(.active):disabled .button-content,
body .mat-mdc-raised-button.select-button:not(.active):disabled .button-content * {
  color: var(--border);
}
body button.select-button.active.blocked,
body .mat-mdc-raised-button.select-button.active.blocked {
  cursor: default;
}
body button.select-button.active:not(:disabled),
body .mat-mdc-raised-button.select-button.active:not(:disabled) {
  background-color: var(--selected);
}
body button.select-button.active:not(:disabled) .button-content,
body button.select-button.active:not(:disabled) .button-content *,
body .mat-mdc-raised-button.select-button.active:not(:disabled) .button-content,
body .mat-mdc-raised-button.select-button.active:not(:disabled) .button-content * {
  color: var(--default);
}
body button.select-button.active:disabled,
body .mat-mdc-raised-button.select-button.active:disabled {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: var(--border);
  border: 1px solid var(--border);
  border-radius: 0.125rem;
}
body button.select-button.active:disabled:not(.loading) .button-content,
body button.select-button.active:disabled:not(.loading) .button-content *,
body .mat-mdc-raised-button.select-button.active:disabled:not(.loading) .button-content,
body .mat-mdc-raised-button.select-button.active:disabled:not(.loading) .button-content * {
  color: var(--default);
}
body button.select-button.active:disabled.loading .button-content,
body button.select-button.active:disabled.loading .button-content *,
body .mat-mdc-raised-button.select-button.active:disabled.loading .button-content,
body .mat-mdc-raised-button.select-button.active:disabled.loading .button-content * {
  color: var(--selected);
}
body button.select-button[color=primary]:disabled .mdc-button__label,
body .mat-mdc-raised-button.select-button[color=primary]:disabled .mdc-button__label {
  color: var(--text-inactive);
  background-color: var(--transparent);
}
body button.select-button[color=primary]:disabled .mdc-button__label .mat-icon,
body .mat-mdc-raised-button.select-button[color=primary]:disabled .mdc-button__label .mat-icon {
  color: var(--text-inactive);
  margin-right: 0.125rem;
}
body button.select-button[color=primary]:not(:disabled) .mdc-button__label,
body .mat-mdc-raised-button.select-button[color=primary]:not(:disabled) .mdc-button__label {
  color: var(--default);
  background-color: var(--transparent);
}
body button.select-button[color=primary]:not(:disabled) .mdc-button__label .mat-icon,
body .mat-mdc-raised-button.select-button[color=primary]:not(:disabled) .mdc-button__label .mat-icon {
  color: var(--default);
  margin-right: 0.125rem;
}
body button.white-button,
body .mat-mdc-raised-button.white-button {
  border: none;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-transform: uppercase;
  font-weight: 700;
  height: min-content;
}
body button.white-button .mdc-button__label,
body .mat-mdc-raised-button.white-button .mdc-button__label {
  line-height: 1.25rem;
}
body .select-icon:not(.active):not(:disabled) .mat-icon path {
  stroke: var(--form-control);
  fill: var(--default);
}
body .select-icon:not(.active):disabled .mat-icon path {
  stroke: var(--border);
  fill: var(--default);
}
body .select-icon.active[size=default] {
  width: 1.25rem;
  height: 1.25rem;
}
body .select-icon.active:not(.bc-none) .mat-icon path:nth-of-type(1) {
  fill: var(--default);
}
body .select-icon.active:not(.bc-none) .mat-icon path:nth-of-type(2) {
  stroke: var(--default);
  fill: none;
}
body .select-icon.active.bc-none .mat-icon path:nth-of-type(1) {
  fill: var(--selected);
}
body .select-icon.active.bc-none .mat-icon path:nth-of-type(2) {
  stroke: var(--selected);
  fill: none;
}
body .checkbox-icon .mat-icon {
  cursor: pointer;
}
body .checkbox-icon:not(.active) .mat-icon path {
  stroke: var(--form-control);
  fill: var(--default);
}
body .checkbox-icon.active .mat-icon path:nth-of-type(1) {
  fill: var(--text-primary);
}
body .checkbox-icon.active .mat-icon path:nth-of-type(2) {
  stroke: var(--form-control);
  fill: none;
}

html,
body {
  --mat-select-container-elevation-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  --mat-autocomplete-container-elevation-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  --mat-select-placeholder-text-color: #0000006b;
}
html .mat-mdc-select,
body .mat-mdc-select {
  display: flex;
}
html div.mat-mdc-select-panel,
html div.mat-mdc-autocomplete-panel,
body div.mat-mdc-select-panel,
body div.mat-mdc-autocomplete-panel {
  background-color: var(--default);
  padding-top: 0rem;
}
html .mat-mdc-option .mdc-list-item__primary-text,
body .mat-mdc-option .mdc-list-item__primary-text {
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-weight: 400;
  font-family: Work Sans;
}
html .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
body .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--selected);
}
html .mat-mdc-option.separator,
body .mat-mdc-option.separator {
  border-bottom: 1px solid var(--border);
  margin: 0.25rem;
  height: 0.01rem;
  min-height: 0.01rem;
}

html .cdk-virtual-scroll-spacer,
body .cdk-virtual-scroll-spacer {
  width: auto !important;
}
html .mat-expansion-panel,
body .mat-expansion-panel {
  background-color: var(--default);
}
html .mat-expansion-panel:not([class*=mat-elevation-z]),
body .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
html .mat-expansion-panel:not([class*=mat-elevation-z]) .mat-expansion-panel-body,
body .mat-expansion-panel:not([class*=mat-elevation-z]) .mat-expansion-panel-body {
  padding: 0;
}
html .mat-drawer-backdrop.mat-drawer-shown,
body .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
html .mat-mdc-autocomplete-panel,
body .mat-mdc-autocomplete-panel {
  border-radius: 0.125rem;
}

html,
body {
  --mdc-outlined-text-field-input-text-placeholder-color: #0000006b;
}
html .mat-pseudo-checkbox,
body .mat-pseudo-checkbox {
  display: none;
}
html .mat-mdc-form-field,
body .mat-mdc-form-field {
  width: 100%;
}
html .mat-mdc-form-field.disabled .mdc-notched-outline__trailing,
body .mat-mdc-form-field.disabled .mdc-notched-outline__trailing {
  background-color: var(--border);
}
html .mat-mdc-form-field:not(.disabled) .mdc-notched-outline__trailing,
body .mat-mdc-form-field:not(.disabled) .mdc-notched-outline__trailing {
  background-color: var(--default);
}
html .mat-mdc-form-field-subscript-wrapper,
body .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper {
  padding: 0;
  margin: 0 !important;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  height: 2.75rem;
  margin: 0 !important;
  top: 0;
  padding: 0 0 0 0.25rem !important;
  display: inline-flex;
  align-items: center;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline {
  top: 0;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__leading {
  display: none;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-notched-outline .mdc-notched-outline__trailing {
  border: 1px solid var(--form-control);
  border-radius: 0.125rem;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-prefix,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-prefix {
  z-index: 1;
  top: 0;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-top-width: 0;
  padding: 0 0.4rem;
  width: 100% !important;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix [matpostfix],
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix [matpostfix] {
  position: relative;
  right: -0.3rem;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix [matpostfix] img,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix [matpostfix] img {
  left: auto;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input:disabled,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix input:disabled {
  color: var(--text-inactive);
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select-arrow-wrapper,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select-arrow-wrapper {
  position: relative;
  height: 0;
}
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
html .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-suffix,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-icon-prefix,
body .mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-suffix {
  top: 0;
  padding: 0;
}
html .mat-mdc-form-field.textarea .mat-mdc-form-field-flex,
body .mat-mdc-form-field.textarea .mat-mdc-form-field-flex {
  height: auto;
}
html .mat-mdc-form-field.textarea .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
body .mat-mdc-form-field.textarea .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  padding: 0.5rem;
}
html .mat-mdc-form-field.textarea textarea,
body .mat-mdc-form-field.textarea textarea {
  min-height: 4rem;
  max-height: 10rem;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-container-color: var(--text-inactive);
  --mdc-plain-tooltip-supporting-text-color: var(--default);
}
html .mat-mdc-tooltip,
html .mdc-tooltip__surface {
  margin: 0rem;
}
html mat-tooltip-component {
  position: relative;
  top: -0.5rem;
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: var(--selected);
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: var(--selected);
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: var(--selected);
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent ;
}

html {
  --mat-stepper-header-height: 3.5rem ;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400 ;
}

html {
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
}
html .mat-mdc-button.mat-primary {
  --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1) ;
}