@use 'mixins' as *;

html {
  //--mdc-text-button-label-text-color: black;
  //--mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  //--mat-text-button-state-layer-color: black;
  //--mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  // --mat-text-button-focus-state-layer-opacity: 0.12;
  // --mat-text-button-pressed-state-layer-opacity: 0.12;
  // --mdc-filled-button-container-color: white;
  // --mdc-filled-button-label-text-color: black;
  // --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  // --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  // --mat-filled-button-state-layer-color: black;
  // --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  // --mat-filled-button-focus-state-layer-opacity: 0.12;
  // --mat-filled-button-pressed-state-layer-opacity: 0.12;
  // --mdc-protected-button-container-color: white;
  // --mdc-protected-button-label-text-color: black;
  // --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  // --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  // --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  // --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  //--mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  //--mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  // --mdc-protected-button-container-shadow-color: #000;
  // --mat-protected-button-state-layer-color: black;
  //--mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  //--mat-protected-button-focus-state-layer-opacity: 0.12;
  //--mat-protected-button-pressed-state-layer-opacity: 0.12;
  // --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  // --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  // --mdc-outlined-button-label-text-color: black;
  // --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  //--mat-outlined-button-state-layer-color: black;
  //--mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  //--mat-outlined-button-focus-state-layer-opacity: 0.12;
  //--mat-outlined-button-pressed-state-layer-opacity: 0.12;

  .mat-mdc-button.mat-primary {
    --mat-text-button-ripple-color: rgba(63, 81, 181, 0.1)
  }
}