@use 'nested' as *;
@use 'functions' as *;
@use 'indents' as *;
@use 'media' as *;

@forward 'variables';
@forward 'nested';
@forward 'functions';
@forward 'indents';
@forward 'media';

@mixin set-background-color($keys...) {
  background-color: color($keys...);
}

@mixin set-color($keys...) {
  color: color($keys...);
}

@mixin set-width($keys...) {
  width: width($keys...);
}

@mixin set-min-width($keys...) {
  min-width: width($keys...);
}

@mixin set-max-width($keys...) {
  max-width: width($keys...);
}

@mixin set-height($keys...) {
  height: height($keys...);
}

@mixin set-max-height($keys...) {
  max-height: height($keys...);
}

@mixin set-min-height($keys...) {
  min-height: height($keys...);
}

@mixin set-border-radius($keys...) {
  border-radius: border-radius($keys...);
}

@mixin set-z-index($keys...) {
  z-index: z-index($keys...);
}

@mixin set-size($keys...) {
  width: size($keys...);
  height: size($keys...);
}

@mixin set-size-height($keys...) {
  height: size($keys...);
}

@mixin set-size-width($keys...) {
  width: size($keys...);
}

@mixin x-centered($position: fixed) {
  position: $position;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

@mixin centered($position: fixed) {
  position: $position;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin flex($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column($flex: '') {
  display: flex;
  flex-direction: column;

  @if($flex !='') {
    flex: $flex;
  }
}

@mixin full-size($important: false) {
  @if($important) {
    width: 100% !important;
    height: 100% !important;
  }

  @else {
    width: 100%;
    height: 100%;
  }
}


@mixin full-size-important {
  @include full-size(true);
}

@mixin set-border($data: (), $sides...) {
  $defaultData: (
    color: 'border',
    width: default,
    style: 'solid',
    radius: 'default'
  );


@each $dataItem in map-keys($defaultData) {
  @if(map-has-key($data, $dataItem)==false) {
    $data: map-merge($data, ($dataItem: map-get($defaultData, $dataItem)));
  }
}

$borderValue: #{width(border, map-get($data, width))} #{map-get($data, style)} #{color(map-get($data, color))};

@if(map-has-key($data, sides)) {
  $sides: map-get($data, sides);
}

@if(length($sides)==0) {
  border: $borderValue;
  border-radius: border-radius(map-get($data, radius));
}

@else {
  @each $side in $sides {
    border-#{$side}: $borderValue;
  }
}
}

@mixin three-dots($lines: '') {
  overflow: hidden;
  text-overflow: ellipsis;

  @if($lines !='') {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
  }

  @else {
    white-space: nowrap;
  }
}

@mixin padding-less($important: '') {
  @if($important=='') {
    padding: 0;
  }

  @else {
    padding: 0 !important;
  }
}

@mixin margin-less($important: '') {
  @if($important=='') {
    margin: 0;
  }

  @else {
    margin: 0 !important;
  }
}

@mixin no-border() {
  border: none;
}

@mixin as-link($decoration: underline, $cursor: pointer, $color: selected) {
  @include set-color($color);
  @include text(1, 1.25, 600);
  text-decoration: $decoration;
  cursor: $cursor;
}

@mixin as-upper-header {
  @include text(0.75, 0.875);
  text-transform: uppercase;
}

//#region icons
@mixin set-stroke($color) {
  stroke: color($color);
}

@mixin set-fill($color) {
  fill: color($color);
}

@mixin set-svg-color($stroke: '', $fill: '') {
  @if($stroke !='') {
    @include set-stroke($stroke);
  }

  @if($fill !='') {
    @include set-fill($fill);
  }
}

@mixin set-path($stroke: '', $fill: '', $children: 0) {
  @if($children !=0) {
    @each $child in $children {
      path:nth-of-type(#{$child}) {
        @include set-svg-color($stroke, $fill);
      }
    }
  }

  @else {
    path {
      @include set-svg-color($stroke, $fill);
    }
  }
}

@mixin set-circle($stroke: '', $fill: '', $children: 0) {
  @if($children !=0) {
    @each $child in $children {
      circle:nth-of-type(#{$child}) {
        @include set-svg-color($stroke, $fill);
      }
    }
  }

  @else {
    circle {
      @include set-svg-color($stroke, $fill);
    }
  }
}

@mixin set-rect($stroke: '', $fill: '', $children: 0) {
  @if($children !=0) {
    @each $child in $children {
      rect:nth-of-type(#{$child}) {
        @include set-svg-color($stroke, $fill);
      }
    }
  }

  @else {
    rect {
      @include set-svg-color($stroke, $fill);
    }
  }
}

@mixin text($fontSize: 1, $lineHeight: 2, $font-weight: 400, $decoration: null) {
  font-size: $fontSize + rem;
  line-height: $lineHeight + rem;
  font-weight: $font-weight;

  @if($decoration !=null) {
    text-decoration: $decoration;
  }
}

@mixin hide-scrollbar() {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
  }
}

@mixin set-hover-opacity() {
  transition: opacity .5s ease-out;
  -moz-transition: opacity .5s ease-out;
  -webkit-transition: opacity .5s ease-out;
  -o-transition: opacity .5s ease-out;

  &:hover {
    opacity: 1;
  }

  &:not(:hover) {
    opacity: 0.9;
  }
}

@mixin icon-button-space($spaceKey: default, $sides...) {
  @each $side in $sides {
    margin-#{$side}: margin(button, $spaceKey);
  }
}

@mixin disableRipple() {

  .mat-ripple,
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
}

//if $positions == null nothing will be set
//if $positions = all - all positions will be set
//if $positions = horizontal - right, left positions will be set
//if $positions = vertical - top, bottom positions will be set
@mixin position($value, $positions: null) {
  position: $value;

  @if ($positions !=null) {
    @each $position in map-keys($positions) {
      $positionValue: map-get($positions, $position);

      @if ($position ==all) {
        top: $positionValue;
        right: $positionValue;
        bottom: $positionValue;
        left: $positionValue;
      }

      @else if ($position ==horizontal) {
        right: $positionValue;
        left: $positionValue;
      }

      @else if ($position ==vertical) {
        top: $positionValue;
        bottom: $positionValue;
      }

      @else {
        #{$position}: $positionValue;
      }
    }
  }
}

@mixin absolute($positions: null) {
  @include position(absolute, $positions);
}

@mixin relative($positions: null) {
  @include position(relative, $positions);
}

@mixin sticky($positions: null) {
  @include position(sticky, $positions);
}

@mixin fixed($positions: null) {
  @include position(fixed, $positions);
}

@mixin host() {
  :host {
    @content;
  }
}

@mixin deep() {
  @include host {
    ::ng-deep {
      @content;
    }
  }
}

@mixin hide-empty-host() {
  @include host {
    &:empty {
      display: none;
    }
  }
}